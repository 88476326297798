@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.step {
  margin-bottom: rem(158);
  padding: rem(40) 0;

  &__desc {
    margin-bottom: rem(85);
    width: 100%;
    max-width: 1130px;
  }

  &__list {
    counter-reset: step-counter;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: rem(48) 24px;

    li {
      counter-increment: step-counter;
      padding: 24px;

      &::before {
        content: counter(step-counter, decimal-leading-zero);
        position: relative;
        display: block;
        margin-bottom: 24px;
        font-weight: var(--fw-600);
        font-size: clamp(32px, 1.5rem + 1.6667vi, 48px);
        line-height: 0.58;
        color: var(--color-green-900);
      }
    }

    h3 {
      margin-bottom: 24px;
      font-size: 20px;
    }

    p {
      font-size: 18px;
    }
  }

  @include media(tablet) {
    margin-bottom: 0;

    &__list {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;

      li {
        padding: 16px;
      }
    }

    &__desc {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
