@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.footer {
  box-shadow: 0 -49px 134px 0 rgba(85, 148, 244, 0.06);

  &__top {
    padding: rem(60) 0;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    width: 100%;
    max-width: 771px;
    gap: rem(77);
  }

  &__bottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-green-50);
    }

    .footer__list {
      display: flex;
      align-items: center;
      gap: rem(40);
    }

    .footer__item {
      &:not(:last-child) {
        margin: 0;
      }
    }
  }

  &__block {
    p {
      margin-bottom: 20px;
      font-weight: var(--fw-500);
      font-size: 20px;
      line-height: 1.25;
    }
  }

  &__item {
    font-size: 20px;
    line-height: 1.6;
    color: var(--color-grey-400);

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__link {
    transition: color 0.4s linear;

    &:hover {
      color: var(--color-green-900);
    }
  }

  @include media(tablet-wide) {
    &__grid {
      gap: 30px;
    }
  }

  @include media(tablet) {
    &__grid {
      justify-content: space-between;
      width: 100%;
      gap: 16px;
    }
  }

  @include media(mobile-l) {
    &__top {
      padding: 30px 0;
    }

    &__bottom {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;
    }

    &__grid {
      justify-content: center;
      grid-template-columns: repeat(2, 1fr);
      width: fit-content;
    }
  }

  @include media(mobile-sm) {
    &__grid {
      grid-template-columns: 1fr;
    }

    &__block {
      text-align: center;
    }
  }
}
