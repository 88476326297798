@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.project {
  position: relative;
  margin-bottom: rem(94);
  padding: 30px 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 435px;
    height: 225px;
    background-image: url("../images/icons/cloud.svg");
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    translate: 180% -27%;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &__image {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 3);
  }

  &__content {
    width: 100%;
    max-width: 805px;
  }

  &__desc {
    display: flex;
    gap: 24px;

    img {
      border-radius: calc(var(--radius-main) * 3);
    }
  }

  &__text {
    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .link {
    margin-bottom: 58px;
  }

  &--main {
    .title {
      margin-bottom: rem(122);
    }
  }

  @include media(desktop) {
    &::before {
      object-fit: contain;
      translate: 120% -41%;
    }
  }

  @include media(tablet) {
    margin-bottom: 0;
    padding: 30px 0;

    &::before {
      left: 16px;
      top: 0;
      translate: 0;
    }

    &__inner {
      flex-direction: column-reverse;
    }

    &__desc {
      align-items: center;
    }

    &__content {
      max-width: 100%;
    }

    &__image {
      img {
        margin: 0 auto;
        border-radius: calc(var(--radius-main) * 3);
        aspect-ratio: 16/9;
      }
    }

    .title {
      margin-bottom: 35px;
    }
  }

  @include media(mobile-l) {
    &__desc {
      flex-direction: column;
    }

    .link {
      margin-bottom: 30px;
    }
  }


  @include media(mobile-s) {
    &::before {
      width: 290px;
      height: 160px;
    }
  }
}
