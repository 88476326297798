@use "../utils/functions/"as *;
@use "../utils/mixins"as *;

.single-products {
  padding: 30px 0 90px;

  &__inner {
    display: flex;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 3);
  }

  &__content {
    background-color: var(--color-grey-10);
  }

  &__nav {
    display: flex;
    margin-bottom: 20px;
    gap: 18px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    padding: 12px 32px 12px 12px;
    width: fit-content;
    color: var(--color-green-900);
    background-color: var(--color-green-50);
    transition:
      background-color 0.3s linear,
      color 0.3 linear;
    gap: 24px;

    &::before {
      content: "";
      position: relative;
      width: 32px;
      height: 32px;
      background-image: url("../images/icons/arrow-left.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &:hover {
      color: var(--color-white);
      background-color: var(--color-green-400);
    }
  }

  &__price {
    border-radius: 40px;
    padding: 16px 32px;
    width: fit-content;
    font-weight: var(--fw-400);
    font-size: 20px;
    line-height: 1.2;
    background-color: var(--color-green-50);
  }

  &__list {
    font-size: 20px;

    span {
      color: var(--color-grey-400);
    }
  }

  h1 {
    margin-bottom: 20px;
    font-size: clamp(18px, 1rem + 1.1111vi, 32px);
    color: var(--color-dark-900);
  }

  @include media(tablet) {
    padding: 0 0 40px;

    &__inner {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}