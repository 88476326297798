@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.values {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 18px;
    line-height: 1.5;
    gap: 24px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px;
    height: 100%;
    text-align: center;

    &::before {
      content: "";
      position: relative;
      align-self: center;
      margin-bottom: 24px;
      width: 72px;
      height: 72px;
      background-image: url("../images/icons/sustainability.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    h3 {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 1.5;
    }

    p {
      font-size: 18px;
    }

    &--sustainability {
      border-right: 2px solid var(--color-green-400);

      &::before {
        background-image: url("../images/icons/sustainability.svg");
      }
    }

    &--innovation {
      &::before {
        background-image: url("../images/icons/innovation.svg");
      }
    }

    &--quality {
      border-left: 2px solid var(--color-green-400);

      &::before {
        background-image: url("../images/icons/simple-quality.svg");
      }
    }

    &--savings {
      border-right: 2px solid var(--color-green-400);

      &::before {
        background-image: url("../images/icons/cost.svg");
      }
    }

    &--impact {
      &::before {
        background-image: url("../images/icons/impact.svg");
      }
    }

    &--energy {
      border-left: 2px solid var(--color-green-400);

      &::before {
        background-image: url("../images/icons/energy.svg");
      }
    }
  }

  &--services {
    .values__item {
      ul {
        padding-left: 28px;
        text-align: left;

        li {
          list-style-type: disc;

          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  @include media(tablet-wide) {
    &__list {
      ul {
        padding: 10px;
      }
    }
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      border: none;
      padding: 5px;

      &:last-child {
        grid-column: 2 span;
      }
    }
  }

  @include media(mobile-l) {
    &--services {
      padding-bottom: 0;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}
