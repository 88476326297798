/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");


:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Lexend", sans-serif;


  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  //
  --color-white: #fff;
  --color-grey-20: #fffbfb;
  --color-grey-10: #fefefe;
  --color-grey-400: #4f4f4f;
  --color-dark-900: #171f23;
  --color-black: #000;
  --color-green-50: #e6ffe4;
  --color-green-100: #caf662;
  --color-green-400: #5ebb29;
  --color-green-900: #1ea13b;

  //  radius
  --radius-main: 8px;
  --gradient-white: linear-gradient(90deg, rgb(249, 242, 231) 25.667%, rgba(231, 243, 249, 0) 100%);
  --gradient-green: linear-gradient(270deg, rgb(94, 187, 41), rgb(30, 161, 59) 100%);
  --gradient-green-hover: linear-gradient(270deg, rgb(76, 160, 28), rgb(19, 72, 31) 100%);
}
