@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.single-article {
  margin-bottom: rem(60);

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: rem(60);
    padding: 24px 0;
    gap: 24px;
  }

  &__content {
    h2 {
      margin-bottom: 24px;
      font-weight: var(--fw-400);
      font-size: clamp(24px, 1.3333rem + 0.7407vi, 32px);
      line-height: 1.25;
    }
  }

  &__subtext {
    margin-bottom: 25px;
    width: 100%;
    max-width: 480px;
    font-size: clamp(18px, 1rem + 0.5556vi, 24px);
    line-height: 1.25;
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: rem(40);

    time,
    span {
      font-size: 20px;
      line-height: 1.6;
      color: var(--color-grey-400);
      opacity: 0.5;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: var(--fw-400);
    font-size: clamp(18px, 1rem + 0.5556vi, 24px);
    line-height: 1.25;
    color: var(--color-green-900);
    gap: 16px;

    &::before {
      content: "";
      position: relative;
      width: 32px;
      height: 32px;
      background-image: url("../images/icons/arrow.svg");
      background-position: 0 0;
      background-size: contain;
      background-repeat: no-repeat;
      transform: scaleX(-1);
    }
  }

  &__desc {
    &:not(:last-child) {
      margin-bottom: rem(60);
    }
  }

  .title {
    margin-bottom: rem(63);
  }

  img {
    border-radius: calc(var(--radius-main) * 3);
    height: 100%;
  }

  p:not(.single-article__subtext) {
    text-align: justify;
    color: var(--color-grey-400);
  }

  @include media(tablet-wide) {
    &__nav {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  @include media(tablet) {
    margin-bottom: 30px;

    &__inner {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
      padding: 0;
    }

    &__subtext {
      max-width: 100%;
    }

    &__desc {
      &:not(:last-child) {
        margin-bottom: rem(30);
      }
    }

    .title {
      margin-bottom: 35px;
    }

    img {
      margin: 0 auto;
      aspect-ratio: 12/9;
    }
  }
}
