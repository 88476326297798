@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.benefits {
  margin-bottom: rem(55);

  &__image {
    position: relative;
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 3);
  }

  &__content {
    position: absolute;
    z-index: 1;
    border-radius: calc(var(--radius-main) * 3);
    padding: 24px 40px;
    width: 100%;
    max-width: 595px;
    color: var(--color-grey-10);
    background: rgba(0, 19, 55, 0.6);
    transform: translate(105px, 0);
    backdrop-filter: blur(72px);

    h2 {
      margin-bottom: 24px;
      line-height: 1.26;
    }
  }

  @include media(tablet-wide) {
    &__content {
      transform: translate(40px, 50px);
    }
  }

  @include media(tablet) {
    margin-bottom: 30px;

    &__inner {
      display: flex;
      flex-direction: column-reverse;
      gap: 24;
    }

    &__content {
      position: relative;
      padding: 16px;
      max-width: 100%;
      transform: none;
    }
  }
}
