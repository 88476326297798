@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.service {
  padding: rem(60) 0;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }

  &__content {
    width: 100%;
    max-width: 493px;
  }

  &__image {
    width: 100%;
    max-width: 616px;

    img {
      width: 100%;
    }
  }

  .link {
    margin-bottom: rem(40);
  }

  &--main {
    margin-bottom: rem(41);
    padding: rem(80) 0;

    .title {
      margin-bottom: rem(42);
    }
  }

  @include media(tablet) {
    padding: 30px 0;

    &--main {
      margin-bottom: 0;
      padding: 35px 0;

      .title {
        margin-bottom: 20px;
      }
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column;
    }

    &__content {
      max-width: 100%;
    }
  }
}
