@import "https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap";
:root {
  --content-width: 1216px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Lexend", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --color-white: #fff;
  --color-grey-20: #fffbfb;
  --color-grey-10: #fefefe;
  --color-grey-400: #4f4f4f;
  --color-dark-900: #171f23;
  --color-black: #000;
  --color-green-50: #e6ffe4;
  --color-green-100: #caf662;
  --color-green-400: #5ebb29;
  --color-green-900: #1ea13b;
  --radius-main: 8px;
  --gradient-white: linear-gradient(90deg, #f9f2e7 25.667%, #e7f3f900 100%);
  --gradient-green: linear-gradient(270deg, #5ebb29, #1ea13b 100%);
  --gradient-green-hover: linear-gradient(270deg, #4ca01c, #13481f 100%);
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-300);
  letter-spacing: 0;
  color: var(--color-black);
  background-color: var(--color-grey-10);
  font-optical-sizing: auto;
  scroll-padding: calc(var(--header-height)  + 60px);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.6;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

.section {
  margin-bottom: 3.75rem;
}

@media only screen and (max-width: 992px) {
  .section {
    margin-bottom: 40px;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main--section {
  padding-top: calc(var(--header-height)  + 89px);
}

@media only screen and (max-width: 992px) {
  .main--section {
    padding-top: calc(var(--header-height)  + 49px);
  }
}

.logo {
  width: 100%;
  max-width: 275px;
  margin-right: 2.6875rem;
}

@media only screen and (max-width: 1180px) {
  .logo {
    max-width: 230px;
  }
}

.is-scrolling {
  scrollbar-color: var(--color-green-900) var(--color-white);
  scrollbar-width: thin;
  overflow-y: scroll;
}

.is-scrolling::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.is-scrolling::-webkit-scrollbar-track {
  background-color: var(--color-white);
  border-radius: 10px;
}

.is-scrolling::-webkit-scrollbar-thumb {
  background-color: var(--color-green-900);
  border-radius: 10px;
}

@media only screen and (max-width: 992px) {
  .is-scrolling {
    padding-bottom: 16px;
  }
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3, .subtitle {
  font-weight: var(--fw-500);
  font-size: clamp(24px, 1.2083rem + 1.2963vi, 38px);
  overflow: hidden;
}

h1 {
  color: var(--color-white);
  margin-bottom: 10px;
  line-height: 1.26;
}

h3 {
  font-size: clamp(18px, .9167rem + .3704vi, 20px);
  line-height: 1.2;
}

.title {
  text-transform: uppercase;
  color: var(--color-green-900);
  margin-bottom: 20px;
  padding-bottom: 12px;
  line-height: 1.05;
  position: relative;
}

.title:before {
  content: "";
  width: 56px;
  height: 3px;
  background-color: var(--color-green-400);
  position: absolute;
  bottom: 0;
}

.subtext, p {
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.subtext {
  font-weight: var(--fw-400);
}

.link {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-400);
  white-space: nowrap;
  color: var(--color-green-900);
  background-color: #0000;
  align-items: center;
  gap: 16px;
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.25;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
  display: flex;
}

.link:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.link:hover {
  -webkit-text-decoration-color: var(--color-green-900);
  text-decoration-color: var(--color-green-900);
}

.header {
  z-index: 10;
  width: 100%;
  transition-property: transform;
  transition-duration: .15s;
  position: fixed;
  top: 30px;
}

.header__inner {
  border: 1px solid var(--color-green-100);
  background-color: var(--color-white);
  border-radius: 100px;
  padding: 14px 16px;
}

.header.hide {
  transform: translateY(-200%);
}

@media only screen and (max-width: 992px) {
  .header {
    top: 15px;
  }

  .header .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 2);
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-grey-10);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .header .nav__list {
    z-index: -1;
    flex-direction: column;
  }

  .header .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .header .nav__link {
    font-size: 20px;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 28px;
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-size: var(--fw-400);
  font-size: 14px;
  line-height: 1.5;
  transition: color .3s linear;
}

.nav__link--sing {
  color: var(--color-green-900);
}

.nav__link:hover {
  color: var(--color-green-400);
}

.nav__link.true {
  background: var(--gradient-green);
  color: var(--color-white);
  border-radius: 82px;
  padding: 12px 19px;
}

.nav__btns {
  align-items: center;
  gap: 23px;
  display: flex;
}

.nav__block {
  width: 100%;
  max-width: 316px;
}

.nav--footer {
  align-items: flex-start;
}

.nav--footer .logo {
  margin-bottom: 20px;
  margin-right: 20px;
  display: block;
}

@media only screen and (max-width: 992px) {
  .nav--footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .nav--footer .logo {
    margin-inline: auto;
  }

  .nav--footer .nav__list {
    margin: 0;
  }
}

@media only screen and (max-width: 475px) {
  .nav--footer .nav__list {
    flex-direction: column;
  }

  .nav--footer .nav__item {
    margin: 0 0 16px;
  }
}

@media only screen and (max-width: 992px) {
  .nav__block {
    max-width: 100%;
    text-align: center;
  }
}

[class].btn {
  width: 100%;
  height: max-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-weight: var(--fw-400);
  color: var(--color-grey-20);
  background: var(--gradient-green);
  border-radius: 82px;
  justify-content: center;
  align-items: center;
  padding: 11px 23px;
  font-size: 14px;
  line-height: 1.5;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  background: var(--gradient-green-hover);
}

[class].btn.formsapp-button {
  font-family: var(--font-family-primary) !important;
}

[class].btn--request {
  color: var(--color-grey-10) !important;
  margin: 0 !important;
}

[class].btn--small {
  border: 1px solid var(--color-green-100);
  white-space: nowrap;
  color: var(--color-dark-900);
  background: var(--color-green-50);
  border-radius: 40px;
  padding: 11px 31px;
  font-size: 20px;
  line-height: 1.2;
}

[class].btn--small:hover, [class].btn--small:focus {
  color: var(--color-grey-10);
  background: var(--color-green-900);
}

[class].btn--arrow {
  color: var(--color-grey-10);
  gap: 24px;
  padding: 12px 12px 12px 32px;
  font-size: 20px;
  line-height: 1.2;
}

[class].btn--arrow:after {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/arrow-right.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.btn-reset {
  font-family: var(--font-family-primary) !important;
  font-weight: var(--fw-400) !important;
  color: var(--color-green-900) !important;
  background-color: #0000 !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  font-size: clamp(18px, 1rem + .5556vi, 24px) !important;
  line-height: 1.25 !important;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-green-900);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy {
  letter-spacing: .02em;
}

.policy__content:not(:last-child) {
  margin-bottom: 3rem;
}

.policy h1 {
  margin-bottom: 3.75rem;
}

.policy h2 {
  margin-bottom: 24px;
  font-size: clamp(18px, .875rem + 1.1111vi, 30px);
  line-height: 1.33;
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  letter-spacing: .02em;
  padding-left: 10px;
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
  color: var(--color-green-900);
}

.policy p {
  opacity: 1;
  line-height: 1.2;
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-green-900);
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .policy h1 {
    margin-bottom: 30px;
  }

  .policy__content:not(:last-child) {
    margin-bottom: 24px;
  }
}

.footer {
  box-shadow: 0 -49px 134px #5594f40f;
}

.footer__top {
  padding: 3.75rem 0;
}

.footer__grid {
  width: 100%;
  max-width: 771px;
  grid-template-columns: repeat(4, auto);
  gap: 4.8125rem;
  display: grid;
}

.footer__bottom {
  justify-content: space-between;
  align-items: center;
  padding: 21px 0;
  display: flex;
  position: relative;
}

.footer__bottom:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-green-50);
  position: absolute;
  top: 0;
}

.footer__bottom .footer__list {
  align-items: center;
  gap: 2.5rem;
  display: flex;
}

.footer__bottom .footer__item:not(:last-child) {
  margin: 0;
}

.footer__block p {
  font-weight: var(--fw-500);
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1.25;
}

.footer__item {
  color: var(--color-grey-400);
  font-size: 20px;
  line-height: 1.6;
}

.footer__item:not(:last-child) {
  margin-bottom: 12px;
}

.footer__link {
  transition: color .4s linear;
}

.footer__link:hover {
  color: var(--color-green-900);
}

@media only screen and (max-width: 1180px) {
  .footer__grid {
    gap: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .footer__grid {
    width: 100%;
    justify-content: space-between;
    gap: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .footer__top {
    padding: 30px 0;
  }

  .footer__bottom {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .footer__grid {
    width: -moz-fit-content;
    width: fit-content;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
}

@media only screen and (max-width: 375px) {
  .footer__grid {
    grid-template-columns: 1fr;
  }

  .footer__block {
    text-align: center;
  }
}

.cards {
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 35px 133px #7190be14;
}

.cards__content {
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
  background: var(--color-grey-10);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  flex-direction: column;
  padding: 24px;
  display: flex;
}

.cards__content .link {
  margin-top: auto;
}

.cards img {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.cards h3 {
  margin-bottom: 20px;
  font-size: clamp(18px, 1rem + 1.1111vi, 32px);
  line-height: 1.25;
}

.cards p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 992px) {
  .cards br {
    display: none;
  }
}

.contacts {
  margin-bottom: 3.75rem;
}

.contacts__wrapper {
  position: relative;
}

.contacts__image {
  border-radius: calc(var(--radius-main) * 3);
  overflow: hidden;
}

.contacts__inner {
  border: 2px solid var(--color-grey-10);
  border-radius: calc(var(--radius-main) * 3);
  width: 100%;
  max-width: 596px;
  color: var(--color-grey-400);
  -webkit-backdrop-filter: blur(66px);
  backdrop-filter: blur(66px);
  background: linear-gradient(90deg, #fefefeb3 0%, #daf6cab3 100%);
  gap: 24px;
  padding: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 106px -86px 100px #2771a024;
}

.contacts__form {
  width: 100%;
  box-shadow: 0 4px 100px #0756c714;
}

.contacts__form label {
  text-align: left;
  display: block;
}

.contacts__form label:last-of-type {
  margin-bottom: 24px;
}

.contacts__form textarea, .contacts__form input {
  width: 100%;
  background-color: var(--color-grey-10);
  border-color: #0000;
  border-radius: 8px;
  padding: 14px 30px 14px 24px;
}

.contacts__form textarea::placeholder, .contacts__form input::placeholder {
  font-size: 20px;
  line-height: 1.25;
}

.contacts__form textarea {
  min-height: 148px;
  resize: none;
  padding: 24px;
}

.contacts__box {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 22px;
  display: grid;
}

.contacts__box label:last-of-type {
  margin-bottom: 0;
}

.contacts h1 {
  color: var(--color-dark-900);
  margin-bottom: 12px;
  font-size: clamp(24px, 1.25rem + 1.1111vi, 36px);
}

.contacts p {
  margin-bottom: 19px;
}

@media only screen and (max-width: 1180px) {
  .contacts__image img {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .contacts__wrapper {
    flex-direction: column;
    align-items: center;
    gap: 24px;
    display: flex;
  }

  .contacts__inner {
    z-index: 1;
    max-width: 100%;
    position: relative;
    translate: 0;
  }
}

@media only screen and (max-width: 768px) and (max-width: 576px) {
  .contacts__inner {
    padding: 16px;
  }

  .contacts__box {
    grid-template-columns: 1fr;
  }

  .contacts .btn {
    max-width: 100%;
  }
}

.hero {
  z-index: 1;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 2.5rem;
  position: relative;
}

.hero__inner {
  min-height: 576px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 3.75rem 0;
  display: flex;
}

.hero__slogan {
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--color-white);
  border-radius: 61px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  padding: 8px 16px 8px 8px;
  font-size: 14px;
  line-height: 1.71;
  display: flex;
  position: relative;
}

.hero__slogan:before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../images/icons/easy.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero__content {
  width: 100%;
  max-width: 423px;
}

.hero p {
  color: var(--color-white);
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.hero .btn {
  color: var(--color-grey-10);
  padding: 14px 25px;
  font-size: 18px;
}

.hero--products, .hero--services {
  margin-bottom: 2.5rem;
}

.hero--products .hero__inner, .hero--services .hero__inner {
  justify-content: center;
}

.hero--products .hero__inner p, .hero--services .hero__inner p {
  margin-bottom: 2.875rem;
}

.hero--products {
  margin-bottom: 5.625rem;
}

.hero--products .hero__inner h1, .hero--products .hero__inner p {
  color: var(--color-dark-900);
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .hero:before {
    content: "";
    z-index: -1;
    background-color: var(--color-white);
    opacity: .8;
    position: absolute;
    inset: 0;
  }

  .hero__inner {
    min-height: 480px;
    justify-content: center;
    padding: 30px 0;
  }

  .hero__inner p {
    margin-bottom: 20px;
  }

  .hero__content {
    max-width: 100%;
  }

  .hero--products, .hero--services {
    margin-bottom: 40px;
  }

  .hero h1, .hero p {
    color: var(--color-dark-900);
  }
}

@media only screen and (max-width: 576px) {
  .hero .btn {
    max-width: 100%;
  }
}

.service {
  padding: 3.75rem 0;
}

.service__inner {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.service__content {
  width: 100%;
  max-width: 493px;
}

.service__image {
  width: 100%;
  max-width: 616px;
}

.service__image img {
  width: 100%;
}

.service .link {
  margin-bottom: 2.5rem;
}

.service--main {
  margin-bottom: 2.5625rem;
  padding: 5rem 0;
}

.service--main .title {
  margin-bottom: 2.625rem;
}

@media only screen and (max-width: 992px) {
  .service {
    padding: 30px 0;
  }

  .service--main {
    margin-bottom: 0;
    padding: 35px 0;
  }

  .service--main .title {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .service__inner {
    flex-direction: column;
  }

  .service__content {
    max-width: 100%;
  }
}

.values__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  font-size: 18px;
  line-height: 1.5;
  display: grid;
}

.values__item {
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.values__item:before {
  content: "";
  width: 72px;
  height: 72px;
  background-image: url("../images/icons/sustainability.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  align-self: center;
  margin-bottom: 24px;
  position: relative;
}

.values__item h3 {
  margin-bottom: 16px;
  font-size: 20px;
  line-height: 1.5;
}

.values__item p {
  font-size: 18px;
}

.values__item--sustainability {
  border-right: 2px solid var(--color-green-400);
}

.values__item--sustainability:before {
  background-image: url("../images/icons/sustainability.svg");
}

.values__item--innovation:before {
  background-image: url("../images/icons/innovation.svg");
}

.values__item--quality {
  border-left: 2px solid var(--color-green-400);
}

.values__item--quality:before {
  background-image: url("../images/icons/simple-quality.svg");
}

.values__item--savings {
  border-right: 2px solid var(--color-green-400);
}

.values__item--savings:before {
  background-image: url("../images/icons/cost.svg");
}

.values__item--impact:before {
  background-image: url("../images/icons/impact.svg");
}

.values__item--energy {
  border-left: 2px solid var(--color-green-400);
}

.values__item--energy:before {
  background-image: url("../images/icons/energy.svg");
}

.values--services .values__item ul {
  text-align: left;
  padding-left: 28px;
}

.values--services .values__item ul li {
  list-style-type: disc;
}

.values--services .values__item ul li:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (max-width: 1180px) {
  .values__list ul {
    padding: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .values__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .values__item {
    border: none;
    padding: 5px;
  }

  .values__item:last-child {
    grid-column: 2 span;
  }
}

@media only screen and (max-width: 768px) {
  .values--services {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .values__list {
    grid-template-columns: 1fr;
  }

  .values__item:last-child {
    grid-column: auto;
  }
}

.tabs__nav {
  align-items: center;
  gap: 21px;
  margin-bottom: 3.75rem;
  display: flex;
}

.tabs__nav--products {
  margin-bottom: 5.1875rem;
}

.tabs .btn.selected {
  color: var(--color-white);
  background: var(--gradient-green);
}

@media only screen and (max-width: 992px) {
  .tabs__nav {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 992px) and (max-width: 768px) {
  .tabs__nav {
    gap: 16px;
  }
}

.products-cards {
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 35px 133px #7190be14;
}

.products-cards__content {
  height: 100%;
  background-color: var(--color-grey-10);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  flex-direction: column;
  padding: 6px 24px 24px;
  display: flex;
}

.products-cards img {
  width: 100%;
}

.products-cards h2 {
  width: 100%;
  max-width: 275px;
  font-weight: var(--fw-400);
  margin-bottom: 22px;
  font-size: clamp(20px, 1rem + 1.1111vi, 32px);
  line-height: 1.25;
}

.products-cards ul {
  margin-bottom: 18px;
  padding-left: 28px;
}

.products-cards ul li {
  letter-spacing: .02em;
  font-size: clamp(16px, 1.0833rem + .1852vi, 20px);
  list-style-type: disc;
}

.products-cards ul li::marker {
  width: 5px;
  height: 5px;
  color: var(--color-black);
}

.products-cards .btn {
  margin-top: auto;
}

@media only screen and (max-width: 992px) {
  .products-cards h2 {
    max-width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .products-cards__content {
    align-items: center;
    padding: 16px;
  }
}

.faq__item:not(:last-child) {
  margin-bottom: 21px;
}

.faq details {
  border: 2px solid var(--color-green-900);
  border-radius: calc((var(--radius-main)) * 3);
  overflow: hidden;
}

.faq details[open] {
  border-color: var(--color-green-400);
}

.faq details[open] summary:after {
  background-color: var(--color-green-400);
  transform: scaleY(1);
}

.faq summary {
  font-weight: var(--fw-500);
  cursor: pointer;
  padding: 24px 56px 24px 32px;
  font-size: clamp(22px, 1rem + .5556vi, 24px);
  line-height: 1.25;
  position: relative;
}

.faq summary:after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  background-color: var(--color-green-900);
  transition: transform .3s linear;
  position: absolute;
  top: 24px;
  right: 24px;
  transform: scaleY(-1);
  -webkit-mask-image: url("../images/icons/chevron-down.svg");
  mask-image: url("../images/icons/chevron-down.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.faq summary + div {
  padding: 0 32px 24px;
}

.faq summary + div p {
  width: 98%;
}

@media only screen and (max-width: 768px) {
  .faq summary + div p {
    width: 100%;
  }
}

.faq .title {
  margin-bottom: 3.75rem;
}

@media only screen and (max-width: 768px) {
  .faq summary {
    padding: 24px 32px;
  }

  .faq summary:after {
    right: 5px;
  }
}

.single-products {
  padding: 30px 0 90px;
}

.single-products__inner {
  border-radius: calc(var(--radius-main) * 3);
  display: flex;
  overflow: hidden;
}

.single-products__content {
  background-color: var(--color-grey-10);
}

.single-products__nav {
  gap: 18px;
  margin-bottom: 20px;
  display: flex;
}

.single-products__link {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--color-green-900);
  background-color: var(--color-green-50);
  transition: background-color .3s linear, color .3 linear;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 12px 32px 12px 12px;
  display: flex;
}

.single-products__link:before {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/arrow-left.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.single-products__link:hover {
  color: var(--color-white);
  background-color: var(--color-green-400);
}

.single-products__price {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-400);
  background-color: var(--color-green-50);
  border-radius: 40px;
  padding: 16px 32px;
  font-size: 20px;
  line-height: 1.2;
}

.single-products__list {
  font-size: 20px;
}

.single-products__list span {
  color: var(--color-grey-400);
}

.single-products h1 {
  color: var(--color-dark-900);
  margin-bottom: 20px;
  font-size: clamp(18px, 1rem + 1.1111vi, 32px);
}

@media only screen and (max-width: 992px) {
  .single-products {
    padding: 0 0 40px;
  }

  .single-products__inner {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.single-projects {
  padding: 30px 0 90px;
}

.single-projects__inner {
  width: 100%;
  max-width: 1113px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 3.75rem;
  display: flex;
}

.single-projects__content {
  width: 100%;
  max-width: 596px;
}

.single-projects__link {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-400);
  color: var(--color-green-900);
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 1.25;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: -webkit-text-decoration-color .4s linear, text-decoration-color .4s linear;
  display: flex;
}

.single-projects__link:before {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  transform: scaleX(-1);
}

.single-projects__link:hover {
  -webkit-text-decoration-color: var(--color-green-900);
  text-decoration-color: var(--color-green-900);
}

.single-projects__desc {
  text-align: justify;
  text-indent: 34px;
  color: var(--color-grey-400);
}

.single-projects .subtext {
  margin-bottom: 20px;
}

.single-projects h1 {
  color: var(--color-dark-900);
  margin-bottom: 20px;
  font-size: clamp(20px, 1rem + 1.1111vi, 32px);
}

.single-projects img {
  border-radius: calc(var(--radius-main) * 3);
}

@media only screen and (max-width: 992px) {
  .single-projects {
    padding: 0 0 40px;
  }

  .single-projects__inner {
    max-width: 100%;
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }

  .single-projects__inner img {
    object-position: top;
    aspect-ratio: 12 / 9;
    margin: 0 auto;
  }

  .single-projects__content {
    max-width: 100%;
  }

  .single-projects__image img {
    min-height: 150px;
  }
}

.news {
  margin-bottom: 35px;
}

.news__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.news__list--offerings {
  counter-reset: offerings-counter;
}

.news__list--offerings .cards__content {
  counter-increment: offerings-counter;
}

.news__list--offerings .cards__content:before {
  content: counter(offerings-counter, decimal);
  width: 32px;
  height: 32px;
  font-weight: var(--fw-700);
  color: var(--color-white);
  background-color: var(--color-green-900);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 2;
  display: flex;
  position: absolute;
  top: 27px;
  left: 24px;
}

@media only screen and (max-width: 768px) {
  .news__list--offerings .cards__content:before {
    top: 24px;
  }
}

.news__list--offerings h3 {
  width: 100%;
  max-width: 250px;
  padding-left: 3.1875rem;
  position: relative;
}

.news__content {
  justify-content: space-between;
  margin-bottom: 20px;
  display: flex;
}

.news--home .title {
  margin-bottom: 33px;
}

.news--home .cards__content h3 {
  font-size: clamp(18px, .9167rem + .9259vi, 28px);
  position: relative;
}

@media only screen and (max-width: 992px) {
  .news__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .news__list li:last-child {
    max-width: 450px;
    grid-column: 2 span;
    margin: 0 auto;
  }

  .news__list h3 {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .news__list {
    grid-template-columns: 1fr;
  }

  .news__list li img {
    aspect-ratio: 18 / 9;
  }

  .news__list li:last-child {
    max-width: 100%;
    grid-column: auto;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 576px) {
  .news__content {
    flex-direction: column;
    gap: 24px;
  }
}

.project {
  margin-bottom: 5.875rem;
  padding: 30px 0;
  position: relative;
}

.project:before {
  content: "";
  z-index: -1;
  width: 435px;
  height: 225px;
  background-image: url("../images/icons/cloud.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  translate: 180% -27%;
}

.project__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.project__image {
  border-radius: calc(var(--radius-main) * 3);
  overflow: hidden;
}

.project__content {
  width: 100%;
  max-width: 805px;
}

.project__desc {
  gap: 24px;
  display: flex;
}

.project__desc img {
  border-radius: calc(var(--radius-main) * 3);
}

.project__text p:not(:last-child) {
  margin-bottom: 20px;
}

.project .link {
  margin-bottom: 58px;
}

.project--main .title {
  margin-bottom: 7.625rem;
}

@media only screen and (max-width: 1280px) {
  .project:before {
    object-fit: contain;
    translate: 120% -41%;
  }
}

@media only screen and (max-width: 992px) {
  .project {
    margin-bottom: 0;
    padding: 30px 0;
  }

  .project:before {
    top: 0;
    left: 16px;
    translate: 0;
  }

  .project__inner {
    flex-direction: column-reverse;
  }

  .project__desc {
    align-items: center;
  }

  .project__content {
    max-width: 100%;
  }

  .project__image img {
    border-radius: calc(var(--radius-main) * 3);
    aspect-ratio: 16 / 9;
    margin: 0 auto;
  }

  .project .title {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .project__desc {
    flex-direction: column;
  }

  .project .link {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 475px) {
  .project:before {
    width: 290px;
    height: 160px;
  }
}

.reviews {
  padding: 5.5rem 0 2.5625rem;
}

.reviews__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.reviews__item {
  border-radius: calc(var(--radius-main) * 4);
  overflow: hidden;
  box-shadow: 0 35px 133px #7190be14;
}

.reviews__item img {
  width: 100%;
}

.reviews__content {
  height: 100%;
  background: var(--color-white);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  flex-direction: column;
  padding: 24px;
  display: flex;
}

.reviews__content h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.reviews__status {
  font-weight: var(--fw-400);
  margin-bottom: 15px;
}

.reviews__desc {
  font-size: 18px;
}

.reviews .title {
  margin-bottom: 3.875rem;
}

.reviews--projects {
  padding-bottom: 4.0625rem;
}

@media only screen and (max-width: 992px) {
  .reviews {
    padding: 40px 0;
  }

  .reviews__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .reviews__list li:last-child {
    max-width: 450px;
    grid-column: 2 span;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .reviews__list {
    grid-template-columns: 1fr;
  }

  .reviews__list li img {
    aspect-ratio: 16 / 9;
  }

  .reviews__list li:last-child {
    max-width: 100%;
    grid-column: auto;
    margin: 0 auto;
  }

  .reviews .title {
    margin-bottom: 30px;
  }
}

.about__inner {
  align-items: center;
  padding-right: max(16px, 50% - 608px);
  display: flex;
}

.about__image {
  width: calc(100% - 620px);
  flex-shrink: 0;
  margin-right: 7.9375rem;
}

.about__content {
  width: 100%;
  max-width: 493px;
  flex-shrink: 0;
  translate: 0 12px;
}

.about .subtitle {
  margin-bottom: 2.5rem;
  line-height: 1.26;
}

@media only screen and (max-width: 992px) {
  .about__inner {
    flex-direction: column-reverse;
    gap: 24px;
    padding: 0 16px;
  }

  .about__image {
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 0;
  }
}

.product__inner {
  justify-content: space-between;
  display: flex;
}

.product__content {
  width: 100%;
  max-width: 493px;
}

.product__image {
  z-index: 2;
  position: relative;
  translate: 0 50px;
}

.product__desc {
  z-index: 1;
  background: var(--gradient-green);
  border-radius: 24px;
  padding: 32px 0 32px 32px;
  position: relative;
  translate: 0 -31px;
}

.product__desc:before {
  content: "";
  width: 215px;
  height: 107px;
  background-image: url("../images/icons/arc.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  right: 0;
}

.product__desc p {
  width: 100%;
  max-width: 535px;
  color: var(--color-grey-10);
}

.product .link, .product .subtitle {
  margin-bottom: 12px;
}

@media only screen and (max-width: 1280px) {
  .product__image {
    translate: 0 31px;
  }

  .product__desc {
    translate: 0 20px;
  }
}

@media only screen and (max-width: 992px) {
  .product__image {
    translate: 0 95px;
  }
}

@media only screen and (max-width: 768px) {
  .product__inner {
    flex-direction: column;
    gap: 24px;
    margin-bottom: 30px;
  }

  .product__content {
    max-width: 100%;
  }

  .product__image, .product__desc {
    translate: 0;
  }

  .product__desc {
    padding: 16px;
  }

  .product__desc:before {
    width: 100px;
    height: 50px;
  }
}

.products {
  margin-bottom: 3.4375rem;
}

.products__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 3.25rem;
  display: grid;
}

@media only screen and (max-width: 992px) {
  .products__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .products__list li:last-child {
    max-width: 450px;
    grid-column: 2 span;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 576px) {
  .products__list {
    grid-template-columns: 1fr;
  }

  .products__list li img {
    aspect-ratio: 12 / 9;
  }

  .products__list li:last-child {
    max-width: 100%;
    grid-column: auto;
    margin: 0;
  }
}

.featured__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.featured__item {
  background-color: var(--color-grey-10);
  border-radius: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 35px 133px #7190be14;
}

.featured__item h3 {
  margin-bottom: 2.4375rem;
  font-size: clamp(18px, 1.0208rem + .463vi, 23px);
  transition: color .3s linear;
}

.featured__item img {
  border-radius: 24px 0 0 24px;
}

.featured__item:hover h3 {
  color: var(--color-green-900);
}

.featured__content {
  background-color: var(--color-grey-10);
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  padding: 20px;
}

.featured__price {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-400);
  background-color: var(--color-green-50);
  border-radius: 40px;
  margin-top: auto;
  padding: 16px 32px;
  font-size: 20px;
  line-height: 1.2;
}

.featured a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.featured h2 {
  margin-bottom: 3.4375rem;
}

@media only screen and (max-width: 1180px) {
  .featured__item {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .featured__content {
    height: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .featured__list, .featured--simple .featured__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .featured--simple .featured__item:last-child {
    width: 100%;
    grid-column: 2 span;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 576px) {
  .featured__list, .featured--simple .featured__list {
    grid-template-columns: 1fr;
  }

  .featured--simple .featured__item:last-child {
    width: 100%;
    grid-column: auto;
  }
}

.blog, .blog__list li:not(:last-child) {
  margin-bottom: 3.75rem;
}

.blog .title {
  margin-bottom: 3.9375rem;
}

@media only screen and (max-width: 768px) {
  .blog, .blog li:not(:last-child) {
    margin-bottom: 30px;
  }

  .blog .title {
    margin-bottom: 35px;
  }
}

.article {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 24px;
  display: grid;
}

.article__content h2 {
  font-weight: var(--fw-400);
  margin-bottom: 24px;
  font-size: clamp(24px, 1.3333rem + .7407vi, 32px);
  line-height: 1.25;
}

.article__content p {
  color: var(--color-grey-400);
}

.article__nav {
  align-items: center;
  gap: 2.5rem;
  margin-bottom: 24px;
  display: flex;
}

.article__nav time, .article__nav span {
  color: var(--color-grey-400);
  opacity: .5;
  font-size: 20px;
  line-height: 1.6;
}

.article img {
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
}

@media only screen and (max-width: 1180px) {
  .article__nav {
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .article {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .article img {
    margin: 0 auto;
  }
}

.single-article {
  margin-bottom: 3.75rem;
}

.single-article__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 3.75rem;
  padding: 24px 0;
  display: grid;
}

.single-article__content h2 {
  font-weight: var(--fw-400);
  margin-bottom: 24px;
  font-size: clamp(24px, 1.3333rem + .7407vi, 32px);
  line-height: 1.25;
}

.single-article__subtext {
  width: 100%;
  max-width: 480px;
  margin-bottom: 25px;
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.25;
}

.single-article__nav {
  align-items: center;
  gap: 2.5rem;
  margin-bottom: 24px;
  display: flex;
}

.single-article__nav time, .single-article__nav span {
  color: var(--color-grey-400);
  opacity: .5;
  font-size: 20px;
  line-height: 1.6;
}

.single-article__link {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-400);
  color: var(--color-green-900);
  align-items: center;
  gap: 16px;
  font-size: clamp(18px, 1rem + .5556vi, 24px);
  line-height: 1.25;
  display: flex;
}

.single-article__link:before {
  content: "";
  width: 32px;
  height: 32px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  transform: scaleX(-1);
}

.single-article__desc:not(:last-child) {
  margin-bottom: 3.75rem;
}

.single-article .title {
  margin-bottom: 3.9375rem;
}

.single-article img {
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
}

.single-article p:not(.single-article__subtext) {
  text-align: justify;
  color: var(--color-grey-400);
}

@media only screen and (max-width: 1180px) {
  .single-article__nav {
    flex-wrap: wrap;
    gap: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .single-article {
    margin-bottom: 30px;
  }

  .single-article__inner {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
    padding: 0;
  }

  .single-article__subtext {
    max-width: 100%;
  }

  .single-article__desc:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  .single-article .title {
    margin-bottom: 35px;
  }

  .single-article img {
    aspect-ratio: 12 / 9;
    margin: 0 auto;
  }
}

.benefits {
  margin-bottom: 3.4375rem;
}

.benefits__image {
  border-radius: calc(var(--radius-main) * 3);
  position: relative;
  overflow: hidden;
}

.benefits__content {
  z-index: 1;
  border-radius: calc(var(--radius-main) * 3);
  width: 100%;
  max-width: 595px;
  color: var(--color-grey-10);
  -webkit-backdrop-filter: blur(72px);
  backdrop-filter: blur(72px);
  background: #00133799;
  padding: 24px 40px;
  position: absolute;
  transform: translate(105px);
}

.benefits__content h2 {
  margin-bottom: 24px;
  line-height: 1.26;
}

@media only screen and (max-width: 1180px) {
  .benefits__content {
    transform: translate(40px, 50px);
  }
}

@media only screen and (max-width: 992px) {
  .benefits {
    margin-bottom: 30px;
  }

  .benefits__inner {
    flex-direction: column-reverse;
    gap: 24px;
    display: flex;
  }

  .benefits__content {
    max-width: 100%;
    padding: 16px;
    position: relative;
    transform: none;
  }
}

.choose {
  margin-bottom: 3.75rem;
}

.choose__inner {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

.choose__content {
  width: 100%;
  max-width: 446px;
}

.choose__content p:first-of-type {
  margin-bottom: 2rem;
}

.choose__image {
  width: 100%;
  max-width: 739px;
}

.choose__image img {
  width: 100%;
}

.choose .title {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .choose {
    margin-bottom: 0;
  }

  .choose__inner {
    flex-direction: column-reverse;
    gap: 24px;
  }

  .choose__content {
    max-width: 100%;
  }
}

.step {
  margin-bottom: 9.875rem;
  padding: 2.5rem 0;
}

.step__desc {
  width: 100%;
  max-width: 1130px;
  margin-bottom: 5.3125rem;
}

.step__list {
  counter-reset: step-counter;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem 24px;
  display: grid;
}

.step__list li {
  counter-increment: step-counter;
  padding: 24px;
}

.step__list li:before {
  content: counter(step-counter, decimal-leading-zero);
  font-weight: var(--fw-600);
  color: var(--color-green-900);
  margin-bottom: 24px;
  font-size: clamp(32px, 1.5rem + 1.6667vi, 48px);
  line-height: .58;
  display: block;
  position: relative;
}

.step__list h3 {
  margin-bottom: 24px;
  font-size: 20px;
}

.step__list p {
  font-size: 18px;
}

@media only screen and (max-width: 992px) {
  .step {
    margin-bottom: 0;
  }

  .step__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .step__list li {
    padding: 16px;
  }

  .step__desc {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .step__list {
    grid-template-columns: 1fr;
  }
}

/*# sourceMappingURL=main.css.map */
