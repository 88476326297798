@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 30px;
  z-index: 10;
  width: 100%;

  transition-property: transform;
  transition-duration: 0.15s;

  &__inner {
    border-radius: 100px;
    border: 1px solid var(--color-green-100);
    padding: 14px 16px;
    background-color: var(--color-white);
  }

  &.hide {
    transform: translateY(-200%);
  }


  @include media(tablet) {
    top: 15px;

    .nav__inner {
      position: fixed;
      left: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 2);
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-grey-10);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }

    .nav__list {
      z-index: -1;
      flex-direction: column;
      // margin-right: 0;
    }

    .nav__item {
      &:not(:last-child) {
        margin-right: 0;
      }
    }

    .nav__link {
      font-size: 20px;
    }
  }
}
