@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.reviews {
  padding: rem(88) 0 rem(41);

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    overflow: hidden;
    border-radius: calc(var(--radius-main) * 4);
    box-shadow: 0 35px 133px 0 rgba(113, 144, 190, 0.08);

    img {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: 100%;
    background: var(--color-white);
    backdrop-filter: blur(40px);

    h3 {
      margin-bottom: 20px;
      font-size: 24px;
    }
  }

  &__status {
    margin-bottom: 15px;
    font-weight: var(--fw-400);
  }

  &__desc {
    font-size: 18px;
  }

  .title {
    margin-bottom: rem(62);
  }

  &--projects {
    padding-bottom: rem(65);
  }

  @include media(tablet) {
    padding: 40px 0;

    &__list {
      grid-template-columns: repeat(2, 1fr);

      li {
        &:last-child {
          grid-column: 2 span;
          margin: 0 auto;
          max-width: 450px;
        }
      }
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;

      li {
        img {
          aspect-ratio: 16/9;
        }

        &:last-child {
          grid-column: auto;
          margin: 0 auto;
          max-width: 100%;
        }
      }
    }

    .title {
      margin-bottom: 30px;
    }
  }
}
