@use "../utils/functions/"as *;
@use "../utils/mixins"as *;

.single-projects {
  padding: 30px 0 90px;

  &__inner {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: rem(60);
    width: 100%;
    max-width: 1113px;
    gap: 16px;
  }

  &__content {
    width: 100%;
    max-width: 596px;
  }

  &__link {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: fit-content;
    font-weight: var(--fw-400);
    font-size: 24px;
    line-height: 1.25;
    text-decoration: underline;
    text-decoration-color: transparent;
    color: var(--color-green-900);
    transition: text-decoration-color 0.4s linear;
    gap: 16px;

    &::before {
      content: "";
      position: relative;
      width: 32px;
      height: 32px;
      background-image: url("../images/icons/arrow.svg");
      background-position: 0 0;
      background-size: contain;
      background-repeat: no-repeat;
      transform: scaleX(-1);
    }

    &:hover {
      text-decoration-color: var(--color-green-900);
    }
  }

  &__desc {
    text-align: justify;
    text-indent: 34px;
    color: var(--color-grey-400);
  }

  .subtext {
    margin-bottom: 20px;
  }

  h1 {
    margin-bottom: 20px;
    font-size: clamp(20px, 1rem + 1.1111vi, 32px);
    color: var(--color-dark-900);
  }

  img {
    border-radius: calc(var(--radius-main) * 3);
  }

  @include media(tablet) {
    padding: 0 0 40px;

    &__inner {
      flex-direction: column-reverse;
      margin-bottom: 30px;
      max-width: 100%;

      img {
        margin: 0 auto;
        object-position: top;
        aspect-ratio: 12/9;
      }
    }

    &__content {
      max-width: 100%;
    }

    &__image {
      img {
        min-height: 150px;
      }
    }
  }
}