@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  $root: &;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 28px;

      @include media(tablet-wide) {
        margin-right: 15px;
      }
    }

    @include media(tablet) {
      margin-bottom: 30px;
    }
  }

  &__link {
    font-size: var(--fw-400);
    font-size: 14px;
    line-height: 1.5;
    transition: color 0.3s linear;

    &--sing {
      color: var(--color-green-900);
    }

    &:hover {
      color: var(--color-green-400);
    }

    &.true {
      padding: 12px 19px;
      border-radius: 82px;
      background: var(--gradient-green);
      color: var(--color-white);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 23px;
  }

  &__block {
    width: 100%;
    max-width: 316px;
  }

  &--footer {
    align-items: flex-start;

    .logo {
      display: block;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    @include media(tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .logo {
        margin-inline: auto;
      }
    }

    #{$root}__list {
      @include media(tablet) {
        margin: 0;
      }

      @include media(mobile-s) {
        flex-direction: column;
      }
    }

    #{$root}__item {
      @include media(mobile-s) {
        margin: 0 0 16px 0;
      }
    }
  }

  @include media(tablet) {
    &__block {
      max-width: 100%;
      text-align: center;
    }
  }
}
