@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.featured {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0 35px 133px 0 rgba(113, 144, 190, 0.08);
    background-color: var(--color-grey-10);

    h3 {
      margin-bottom: rem(39);
      font-size: clamp(18px, 1.0208rem + 0.463vi, 23px);
      transition: color 0.3s linear;
    }

    img {
      border-radius: 24px 0 0 24px;
    }

    &:hover {
      h3 {
        color: var(--color-green-900);
      }
    }
  }

  &__content {
    padding: 20px;
    background-color: var(--color-grey-10);
    backdrop-filter: blur(40px);
  }

  &__price {
    margin-top: auto;
    border-radius: 40px;
    padding: 16px 32px;
    width: fit-content;
    font-weight: var(--fw-400);
    font-size: 20px;
    line-height: 1.2;
    background-color: var(--color-green-50);
  }

  a {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
    }
  }

  h2 {
    margin-bottom: rem(55);
  }

  @include media(tablet-wide) {
    &__item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &--simple {
      .featured__list {
        grid-template-columns: repeat(2, 1fr);
      }

      .featured__item {
        &:last-child {
          grid-column: 2 span;
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }

    &--simple {
      .featured__list {
        grid-template-columns: 1fr;
      }

      .featured__item {
        &:last-child {
          grid-column: auto;
          width: 100%;
        }
      }
    }
  }
}
