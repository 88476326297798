@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.article {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 24px;
  gap: 24px;

  &__content {
    h2 {
      margin-bottom: 24px;
      font-weight: var(--fw-400);
      font-size: clamp(24px, 1.3333rem + 0.7407vi, 32px);
      line-height: 1.25;
    }

    p {
      color: var(--color-grey-400);
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: rem(40);

    time,
    span {
      font-size: 20px;
      line-height: 1.6;
      color: var(--color-grey-400);
      opacity: 0.5;
    }
  }

  img {
    border-radius: calc(var(--radius-main) * 3);
    height: 100%;
  }

  @include media(tablet-wide) {
    &__nav {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  @include media(tablet) {
    grid-template-columns: 1fr;
    padding: 0;

    img {
      margin: 0 auto;
    }
  }
}
