@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

.cards {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: calc(var(--radius-main) * 3);
  height: 100%;
  box-shadow: 0 35px 133px 0 rgba(113, 144, 190, 0.08);

  &__content {
    display: flex;
    flex-direction: column;
    border-radius: calc(var(--radius-main) * 3);
    padding: 24px;
    height: 100%;
    background: var(--color-grey-10);
    backdrop-filter: blur(40px);

    .link {
      margin-top: auto;
    }
  }

  img {
    border-radius: 8px 8px 0 0;
    width: 100%;
  }

  h3 {
    margin-bottom: 20px;
    font-size: clamp(18px, 1rem + 1.1111vi, 32px);
    line-height: 1.25;
  }

  p {
    margin-bottom: 30px;
  }

  @include media(tablet) {
    br {
      display: none;
    }
  }
}
