@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  $root: &;
  position: relative;
  z-index: 1;
  margin-bottom: rem(40);

  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: rem(60) 0;
    min-height: 576px;
  }

  &__slogan {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    border-radius: 61px;
    padding: 8px 16px 8px 8px;
    width: fit-content;
    font-size: 14px;
    line-height: 1.71;
    background-color: var(--color-white);
    gap: 8px;

    &::before {
      content: "";
      position: relative;
      width: 20px;
      height: 20px;
      background-image: url("../images/icons/easy.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__content {
    width: 100%;
    max-width: 423px;
  }

  p {
    margin-bottom: rem(40);
    line-height: 1.6;
    color: var(--color-white);
  }

  .btn {
    padding: 14px 25px;
    font-size: 18px;
    color: var(--color-grey-10);
  }

  &--products,
  &--services {
    margin-bottom: rem(40);
    #{$root}__inner {
      justify-content: center;
      p {
        margin-bottom: rem(46);
      }
    }
  }

  &--products {
    margin-bottom: rem(90);
    #{$root}__inner {
      h1,
      p {
        margin-bottom: 24px;
        color: var(--color-dark-900);
      }
    }
  }

  @include media(tablet) {
    &::before {
      content: "";
      z-index: -1;
      position: absolute;
      background-color: var(--color-white);
      inset: 0;
      opacity: 0.8;
    }

    &__inner {
      padding: 16px 0;
      justify-content: center;
      min-height: 480px;
      padding: 30px 0;

      p {
        margin-bottom: 20px;
      }
    }

    &__content {
      max-width: 100%;
    }

    &--products,
    &--services {
      margin-bottom: 40px;
    }

    h1,
    p {
      color: var(--color-dark-900);
    }
  }

  @include media(mobile-m) {
    .btn {
      max-width: 100%;
    }
  }
}
