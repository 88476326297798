@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.product {
  &__inner {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    width: 100%;
    max-width: 493px;
  }

  &__image {
    position: relative;
    z-index: 2;
    translate: 0 50px;
  }

  &__desc {
    position: relative;
    z-index: 1;
    border-radius: 24px;
    padding: 32px 0 32px 32px;
    background: var(--gradient-green);
    translate: 0 -31px;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 215px;
      height: 107px;
      background-image: url("../images/icons/arc.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }

    p {
      width: 100%;
      max-width: 535px;
      color: var(--color-grey-10);
    }
  }

  .link {
    margin-bottom: 12px;
  }

  .subtitle {
    margin-bottom: 12px;
  }

  @include media(desktop) {
    &__image {
      translate: 0 31px;
    }

    &__desc {
      translate: 0 20px;
    }
  }

  @include media(tablet) {
    &__image {
      translate: 0 95px;
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column;
      margin-bottom: 30px;
      gap: 24px;
    }

    &__content {
      max-width: 100%;
    }

    &__image,
    &__desc {
      translate: 0;
    }

    &__desc {
      padding: 16px;

      &::before {
        width: 100px;
        height: 50px;
      }
    }
  }
}
