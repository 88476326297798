@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.choose {
  margin-bottom: rem(60);

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__content {
    width: 100%;
    max-width: 446px;

    p {
      &:first-of-type {
        margin-bottom: rem(32);
      }
    }
  }

  &__image {
    width: 100%;
    max-width: 739px;

    img {
      width: 100%;
    }
  }

  .title {
    margin-bottom: 0;
  }

  @include media(tablet) {
    margin-bottom: 0;
    
    &__inner {
      flex-direction: column-reverse;
      gap: 24px;
    }

    &__content {
      max-width: 100%;
    }
  }
}
